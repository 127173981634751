.no-list {
  list-style: none;
}
// Generic Box - Uses Flex
.box {
  padding: .5rem;
}
// Flex Alignment
.bottom-align {
  display: flex;
  align-items: flex-end;
  flex: 1;
}
.center-center {
  display: flex;
  justify-content: center;
  flex: 1;
}
.flex-right {
  justify-content: flex-end;
}
.v-center {
  margin: 0rem auto 1.2rem auto;
}
.width-20 {
  width: 20%;
}
// Background Classes
.white-bg {
  background-color: #ffffff;
}
.medium-grey-bg {
  background-color: #dedede;
}
.grey-bg {
  background-color: #5a5a5a;
}
.lt-grey-bg {
  background-color: #f9f9f9;
}
// horizontal lines, sized: 1 - 5
.hl-1 {
  height: 1px;
  width: 100%;
  margin: .5rem 0 1rem 0;
}
.hl-2 {
  height: 2px;
  width: 100%;
  margin: .5rem 0 1rem 0;
}
.hl-3 {
  height: 3px;
  width: 100%;
  margin: .5rem 0 1rem 0;
}
.hl-4 {
  height: 4px;
  width: 100%;
  margin: .5rem 0 1rem 0;
}
.hl-5 {
  height: 5px;
  width: 100%;
  margin: .5rem 0 1rem 0;
}
// Borders
.bdr-1 {
  border: 1px solid #eaeaea;
}
// padding classes
.pad {
  padding: .5rem;
}
.pad-0 {
  padding: 0;
}
.pad-1 {
  padding: 1rem;
}
.pad-2 {
  padding: 2rem;
}
.pad-3 {
  padding: 3rem;
}
.pad-4 {
  padding: 4rem;
}
.pad-5 {
  padding: 4rem;
}
.pad-t-1 {
  padding-top: 1rem;
}
.pad-tb-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pad-tb-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pad-lr-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pad-lr-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.pad-t-1 {
  padding-top: 1rem;
}
.pad-t-2 {
  padding-top: 2rem;
}
.pad-t-3 {
  padding-top: 3rem;
}

.pad-b-1 {
  padding-bottom: 1rem;
}
.pad-b-2 {
  padding-bottom: 2rem;
}
.pad-b-3 {
  padding-bottom: 3rem;
}
// Margin Classes
.m-0 {
  margin: 0;
}
.mt {
  margin-top: .5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb {
  margin-bottom: .5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mr {
  margin-right: .5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.ml {
  margin-left: .5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

form span.red {
  position: relative;
  top: -8px;
  font-style: italic;
  color: red;
  font-size: .8rem;
}

.ml-1 {
  margin-left: 1rem;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: left;
  margin: 0 0 1rem 0;
  li {
    padding: 0 .3rem;
  }
}