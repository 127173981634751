// Place Variables Here
$primary: #008bfb;
$red: #ee4037;
// End Variables
@import "~bulma";
@import "vendor/billow";
@import "node_modules/font-awesome/scss/font-awesome";

.menu-label {
  color: $red;
}

.menu {
  font-size: 0.75rem;
}

.section {
  background-color: white;
  padding: 1rem 1.5rem;
}

.page-header {
  color: $primary;
}
