.card-header {
  align-items: stretch;
  box-shadow: 0 1px 2px rgba($black, 0.1);
  display: flex; }

.card-header-title {
  align-items: center;
  color: $text-strong;
  display: flex;
  flex-grow: 1;
  font-weight: $weight-bold;
  padding: 0.75rem; }

.card-header-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem; }

.card-image {
  display: block;
  position: relative; }

.card-content {
  padding: 1.5rem; }

.card-footer {
  border-top: 1px solid $border;
  align-items: stretch;
  display: flex; }

.card-footer-item {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  padding: 0.75rem;
  &:not(:last-child) {
    border-right: 1px solid $border; } }

.card {
  background-color: $white;
  box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
  color: $text;
  max-width: 100%;
  position: relative;
  .media:not(:last-child) {
    margin-bottom: 0.75rem; } }
