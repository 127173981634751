.notification {
  @include block;
  background-color: $background;
  border-radius: $radius;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
  a:not(.button) {
    color: currentColor;
    text-decoration: underline; }
  code,
  pre {
    background: $white; }
  pre code {
    background: transparent; }
  & > .delete {
    position: absolute;
    right: 0.5em;
    top: 0.5em; }
  .title,
  .subtitle,
  .content {
    color: inherit; }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert; } } }
